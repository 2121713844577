// source: store_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var agent_pb = require('./agent_pb.js');
goog.object.extend(proto, agent_pb);
var instance_pb = require('./instance_pb.js');
goog.object.extend(proto, instance_pb);
var console_pb = require('./console_pb.js');
goog.object.extend(proto, console_pb);
var web_pb = require('./web_pb.js');
goog.object.extend(proto, web_pb);
var publishpacket_pb = require('./publishpacket_pb.js');
goog.object.extend(proto, publishpacket_pb);
var request_pb = require('./request_pb.js');
goog.object.extend(proto, request_pb);
var watcher_pb = require('./watcher_pb.js');
goog.object.extend(proto, watcher_pb);
var alarm_pb = require('./alarm_pb.js');
goog.object.extend(proto, alarm_pb);
var config_pb = require('./config_pb.js');
goog.object.extend(proto, config_pb);
var problem_pb = require('./problem_pb.js');
goog.object.extend(proto, problem_pb);
var agent_state_pb = require('./agent_state_pb.js');
goog.object.extend(proto, agent_state_pb);
