/**
 * @fileoverview gRPC-Web generated client stub for pb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: aaa_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var audit_pb = require('./audit_pb.js')

var user_pb = require('./user_pb.js')

var policy_pb = require('./policy_pb.js')
const proto = {};
proto.pb = require('./aaa_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.AaaServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.AaaServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.User,
 *   !proto.pb.User>}
 */
const methodDescriptor_AaaService_FromContext = new grpc.web.MethodDescriptor(
  '/pb.AaaService/FromContext',
  grpc.web.MethodType.UNARY,
  user_pb.User,
  user_pb.User,
  /**
   * @param {!proto.pb.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  user_pb.User.deserializeBinary
);


/**
 * @param {!proto.pb.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AaaServiceClient.prototype.fromContext =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.AaaService/FromContext',
      request,
      metadata || {},
      methodDescriptor_AaaService_FromContext,
      callback);
};


/**
 * @param {!proto.pb.User} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.User>}
 *     Promise that resolves to the response
 */
proto.pb.AaaServicePromiseClient.prototype.fromContext =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.AaaService/FromContext',
      request,
      metadata || {},
      methodDescriptor_AaaService_FromContext);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.User,
 *   !proto.pb.User>}
 */
const methodDescriptor_AaaService_FilterUsers = new grpc.web.MethodDescriptor(
  '/pb.AaaService/FilterUsers',
  grpc.web.MethodType.SERVER_STREAMING,
  user_pb.User,
  user_pb.User,
  /**
   * @param {!proto.pb.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  user_pb.User.deserializeBinary
);


/**
 * @param {!proto.pb.User} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.User>}
 *     The XHR Node Readable Stream
 */
proto.pb.AaaServiceClient.prototype.filterUsers =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.AaaService/FilterUsers',
      request,
      metadata || {},
      methodDescriptor_AaaService_FilterUsers);
};


/**
 * @param {!proto.pb.User} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.User>}
 *     The XHR Node Readable Stream
 */
proto.pb.AaaServicePromiseClient.prototype.filterUsers =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.AaaService/FilterUsers',
      request,
      metadata || {},
      methodDescriptor_AaaService_FilterUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.User,
 *   !proto.pb.User>}
 */
const methodDescriptor_AaaService_UpdateUserAttributes = new grpc.web.MethodDescriptor(
  '/pb.AaaService/UpdateUserAttributes',
  grpc.web.MethodType.UNARY,
  user_pb.User,
  user_pb.User,
  /**
   * @param {!proto.pb.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  user_pb.User.deserializeBinary
);


/**
 * @param {!proto.pb.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AaaServiceClient.prototype.updateUserAttributes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.AaaService/UpdateUserAttributes',
      request,
      metadata || {},
      methodDescriptor_AaaService_UpdateUserAttributes,
      callback);
};


/**
 * @param {!proto.pb.User} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.User>}
 *     Promise that resolves to the response
 */
proto.pb.AaaServicePromiseClient.prototype.updateUserAttributes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.AaaService/UpdateUserAttributes',
      request,
      metadata || {},
      methodDescriptor_AaaService_UpdateUserAttributes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.User,
 *   !proto.pb.User>}
 */
const methodDescriptor_AaaService_NewUser = new grpc.web.MethodDescriptor(
  '/pb.AaaService/NewUser',
  grpc.web.MethodType.UNARY,
  user_pb.User,
  user_pb.User,
  /**
   * @param {!proto.pb.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  user_pb.User.deserializeBinary
);


/**
 * @param {!proto.pb.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AaaServiceClient.prototype.newUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.AaaService/NewUser',
      request,
      metadata || {},
      methodDescriptor_AaaService_NewUser,
      callback);
};


/**
 * @param {!proto.pb.User} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.User>}
 *     Promise that resolves to the response
 */
proto.pb.AaaServicePromiseClient.prototype.newUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.AaaService/NewUser',
      request,
      metadata || {},
      methodDescriptor_AaaService_NewUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.User,
 *   !proto.pb.User>}
 */
const methodDescriptor_AaaService_DeleteUser = new grpc.web.MethodDescriptor(
  '/pb.AaaService/DeleteUser',
  grpc.web.MethodType.UNARY,
  user_pb.User,
  user_pb.User,
  /**
   * @param {!proto.pb.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  user_pb.User.deserializeBinary
);


/**
 * @param {!proto.pb.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AaaServiceClient.prototype.deleteUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.AaaService/DeleteUser',
      request,
      metadata || {},
      methodDescriptor_AaaService_DeleteUser,
      callback);
};


/**
 * @param {!proto.pb.User} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.User>}
 *     Promise that resolves to the response
 */
proto.pb.AaaServicePromiseClient.prototype.deleteUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.AaaService/DeleteUser',
      request,
      metadata || {},
      methodDescriptor_AaaService_DeleteUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.User,
 *   !proto.pb.User>}
 */
const methodDescriptor_AaaService_HasValidSessionKey = new grpc.web.MethodDescriptor(
  '/pb.AaaService/HasValidSessionKey',
  grpc.web.MethodType.UNARY,
  user_pb.User,
  user_pb.User,
  /**
   * @param {!proto.pb.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  user_pb.User.deserializeBinary
);


/**
 * @param {!proto.pb.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AaaServiceClient.prototype.hasValidSessionKey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.AaaService/HasValidSessionKey',
      request,
      metadata || {},
      methodDescriptor_AaaService_HasValidSessionKey,
      callback);
};


/**
 * @param {!proto.pb.User} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.User>}
 *     Promise that resolves to the response
 */
proto.pb.AaaServicePromiseClient.prototype.hasValidSessionKey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.AaaService/HasValidSessionKey',
      request,
      metadata || {},
      methodDescriptor_AaaService_HasValidSessionKey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Audit,
 *   !proto.pb.Audit>}
 */
const methodDescriptor_AaaService_LogAudit = new grpc.web.MethodDescriptor(
  '/pb.AaaService/LogAudit',
  grpc.web.MethodType.UNARY,
  audit_pb.Audit,
  audit_pb.Audit,
  /**
   * @param {!proto.pb.Audit} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  audit_pb.Audit.deserializeBinary
);


/**
 * @param {!proto.pb.Audit} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Audit)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Audit>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AaaServiceClient.prototype.logAudit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.AaaService/LogAudit',
      request,
      metadata || {},
      methodDescriptor_AaaService_LogAudit,
      callback);
};


/**
 * @param {!proto.pb.Audit} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Audit>}
 *     Promise that resolves to the response
 */
proto.pb.AaaServicePromiseClient.prototype.logAudit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.AaaService/LogAudit',
      request,
      metadata || {},
      methodDescriptor_AaaService_LogAudit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.AuditRange,
 *   !proto.pb.Audit>}
 */
const methodDescriptor_AaaService_FilterAudit = new grpc.web.MethodDescriptor(
  '/pb.AaaService/FilterAudit',
  grpc.web.MethodType.SERVER_STREAMING,
  audit_pb.AuditRange,
  audit_pb.Audit,
  /**
   * @param {!proto.pb.AuditRange} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  audit_pb.Audit.deserializeBinary
);


/**
 * @param {!proto.pb.AuditRange} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Audit>}
 *     The XHR Node Readable Stream
 */
proto.pb.AaaServiceClient.prototype.filterAudit =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.AaaService/FilterAudit',
      request,
      metadata || {},
      methodDescriptor_AaaService_FilterAudit);
};


/**
 * @param {!proto.pb.AuditRange} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Audit>}
 *     The XHR Node Readable Stream
 */
proto.pb.AaaServicePromiseClient.prototype.filterAudit =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.AaaService/FilterAudit',
      request,
      metadata || {},
      methodDescriptor_AaaService_FilterAudit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Policy,
 *   !proto.pb.Policy>}
 */
const methodDescriptor_AaaService_FilterPolicies = new grpc.web.MethodDescriptor(
  '/pb.AaaService/FilterPolicies',
  grpc.web.MethodType.SERVER_STREAMING,
  policy_pb.Policy,
  policy_pb.Policy,
  /**
   * @param {!proto.pb.Policy} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  policy_pb.Policy.deserializeBinary
);


/**
 * @param {!proto.pb.Policy} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Policy>}
 *     The XHR Node Readable Stream
 */
proto.pb.AaaServiceClient.prototype.filterPolicies =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.AaaService/FilterPolicies',
      request,
      metadata || {},
      methodDescriptor_AaaService_FilterPolicies);
};


/**
 * @param {!proto.pb.Policy} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Policy>}
 *     The XHR Node Readable Stream
 */
proto.pb.AaaServicePromiseClient.prototype.filterPolicies =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.AaaService/FilterPolicies',
      request,
      metadata || {},
      methodDescriptor_AaaService_FilterPolicies);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Policy,
 *   !proto.pb.Policy>}
 */
const methodDescriptor_AaaService_SyncPolicies = new grpc.web.MethodDescriptor(
  '/pb.AaaService/SyncPolicies',
  grpc.web.MethodType.UNARY,
  policy_pb.Policy,
  policy_pb.Policy,
  /**
   * @param {!proto.pb.Policy} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  policy_pb.Policy.deserializeBinary
);


/**
 * @param {!proto.pb.Policy} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Policy)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Policy>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AaaServiceClient.prototype.syncPolicies =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.AaaService/SyncPolicies',
      request,
      metadata || {},
      methodDescriptor_AaaService_SyncPolicies,
      callback);
};


/**
 * @param {!proto.pb.Policy} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Policy>}
 *     Promise that resolves to the response
 */
proto.pb.AaaServicePromiseClient.prototype.syncPolicies =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.AaaService/SyncPolicies',
      request,
      metadata || {},
      methodDescriptor_AaaService_SyncPolicies);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Policy,
 *   !proto.pb.Policy>}
 */
const methodDescriptor_AaaService_GetPolicy = new grpc.web.MethodDescriptor(
  '/pb.AaaService/GetPolicy',
  grpc.web.MethodType.UNARY,
  policy_pb.Policy,
  policy_pb.Policy,
  /**
   * @param {!proto.pb.Policy} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  policy_pb.Policy.deserializeBinary
);


/**
 * @param {!proto.pb.Policy} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Policy)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Policy>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AaaServiceClient.prototype.getPolicy =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.AaaService/GetPolicy',
      request,
      metadata || {},
      methodDescriptor_AaaService_GetPolicy,
      callback);
};


/**
 * @param {!proto.pb.Policy} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Policy>}
 *     Promise that resolves to the response
 */
proto.pb.AaaServicePromiseClient.prototype.getPolicy =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.AaaService/GetPolicy',
      request,
      metadata || {},
      methodDescriptor_AaaService_GetPolicy);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Policy,
 *   !proto.pb.Policy>}
 */
const methodDescriptor_AaaService_AddPolicy = new grpc.web.MethodDescriptor(
  '/pb.AaaService/AddPolicy',
  grpc.web.MethodType.UNARY,
  policy_pb.Policy,
  policy_pb.Policy,
  /**
   * @param {!proto.pb.Policy} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  policy_pb.Policy.deserializeBinary
);


/**
 * @param {!proto.pb.Policy} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Policy)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Policy>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AaaServiceClient.prototype.addPolicy =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.AaaService/AddPolicy',
      request,
      metadata || {},
      methodDescriptor_AaaService_AddPolicy,
      callback);
};


/**
 * @param {!proto.pb.Policy} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Policy>}
 *     Promise that resolves to the response
 */
proto.pb.AaaServicePromiseClient.prototype.addPolicy =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.AaaService/AddPolicy',
      request,
      metadata || {},
      methodDescriptor_AaaService_AddPolicy);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Policy,
 *   !proto.pb.Policy>}
 */
const methodDescriptor_AaaService_RemovePolicy = new grpc.web.MethodDescriptor(
  '/pb.AaaService/RemovePolicy',
  grpc.web.MethodType.UNARY,
  policy_pb.Policy,
  policy_pb.Policy,
  /**
   * @param {!proto.pb.Policy} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  policy_pb.Policy.deserializeBinary
);


/**
 * @param {!proto.pb.Policy} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Policy)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Policy>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AaaServiceClient.prototype.removePolicy =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.AaaService/RemovePolicy',
      request,
      metadata || {},
      methodDescriptor_AaaService_RemovePolicy,
      callback);
};


/**
 * @param {!proto.pb.Policy} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Policy>}
 *     Promise that resolves to the response
 */
proto.pb.AaaServicePromiseClient.prototype.removePolicy =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.AaaService/RemovePolicy',
      request,
      metadata || {},
      methodDescriptor_AaaService_RemovePolicy);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.PolicySet,
 *   !proto.pb.PolicySet>}
 */
const methodDescriptor_AaaService_SetPolicySet = new grpc.web.MethodDescriptor(
  '/pb.AaaService/SetPolicySet',
  grpc.web.MethodType.UNARY,
  policy_pb.PolicySet,
  policy_pb.PolicySet,
  /**
   * @param {!proto.pb.PolicySet} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  policy_pb.PolicySet.deserializeBinary
);


/**
 * @param {!proto.pb.PolicySet} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.PolicySet)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.PolicySet>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AaaServiceClient.prototype.setPolicySet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.AaaService/SetPolicySet',
      request,
      metadata || {},
      methodDescriptor_AaaService_SetPolicySet,
      callback);
};


/**
 * @param {!proto.pb.PolicySet} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.PolicySet>}
 *     Promise that resolves to the response
 */
proto.pb.AaaServicePromiseClient.prototype.setPolicySet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.AaaService/SetPolicySet',
      request,
      metadata || {},
      methodDescriptor_AaaService_SetPolicySet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Revision,
 *   !proto.pb.Revision>}
 */
const methodDescriptor_AaaService_PolicyRevision = new grpc.web.MethodDescriptor(
  '/pb.AaaService/PolicyRevision',
  grpc.web.MethodType.UNARY,
  policy_pb.Revision,
  policy_pb.Revision,
  /**
   * @param {!proto.pb.Revision} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  policy_pb.Revision.deserializeBinary
);


/**
 * @param {!proto.pb.Revision} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Revision)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Revision>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AaaServiceClient.prototype.policyRevision =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.AaaService/PolicyRevision',
      request,
      metadata || {},
      methodDescriptor_AaaService_PolicyRevision,
      callback);
};


/**
 * @param {!proto.pb.Revision} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Revision>}
 *     Promise that resolves to the response
 */
proto.pb.AaaServicePromiseClient.prototype.policyRevision =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.AaaService/PolicyRevision',
      request,
      metadata || {},
      methodDescriptor_AaaService_PolicyRevision);
};


module.exports = proto.pb;

