/**
 * @fileoverview gRPC-Web generated client stub for pb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: library_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var recipe_pb = require('./recipe_pb.js')
const proto = {};
proto.pb = require('./library_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.LibraryServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.LibraryServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Recipe,
 *   !proto.pb.Recipe>}
 */
const methodDescriptor_LibraryService_NewRecipe = new grpc.web.MethodDescriptor(
  '/pb.LibraryService/NewRecipe',
  grpc.web.MethodType.UNARY,
  recipe_pb.Recipe,
  recipe_pb.Recipe,
  /**
   * @param {!proto.pb.Recipe} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  recipe_pb.Recipe.deserializeBinary
);


/**
 * @param {!proto.pb.Recipe} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Recipe)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Recipe>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.LibraryServiceClient.prototype.newRecipe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.LibraryService/NewRecipe',
      request,
      metadata || {},
      methodDescriptor_LibraryService_NewRecipe,
      callback);
};


/**
 * @param {!proto.pb.Recipe} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Recipe>}
 *     Promise that resolves to the response
 */
proto.pb.LibraryServicePromiseClient.prototype.newRecipe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.LibraryService/NewRecipe',
      request,
      metadata || {},
      methodDescriptor_LibraryService_NewRecipe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Recipe,
 *   !proto.pb.Recipe>}
 */
const methodDescriptor_LibraryService_GetRecipe = new grpc.web.MethodDescriptor(
  '/pb.LibraryService/GetRecipe',
  grpc.web.MethodType.UNARY,
  recipe_pb.Recipe,
  recipe_pb.Recipe,
  /**
   * @param {!proto.pb.Recipe} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  recipe_pb.Recipe.deserializeBinary
);


/**
 * @param {!proto.pb.Recipe} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Recipe)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Recipe>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.LibraryServiceClient.prototype.getRecipe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.LibraryService/GetRecipe',
      request,
      metadata || {},
      methodDescriptor_LibraryService_GetRecipe,
      callback);
};


/**
 * @param {!proto.pb.Recipe} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Recipe>}
 *     Promise that resolves to the response
 */
proto.pb.LibraryServicePromiseClient.prototype.getRecipe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.LibraryService/GetRecipe',
      request,
      metadata || {},
      methodDescriptor_LibraryService_GetRecipe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Recipe,
 *   !proto.pb.Recipe>}
 */
const methodDescriptor_LibraryService_FilterRecipes = new grpc.web.MethodDescriptor(
  '/pb.LibraryService/FilterRecipes',
  grpc.web.MethodType.SERVER_STREAMING,
  recipe_pb.Recipe,
  recipe_pb.Recipe,
  /**
   * @param {!proto.pb.Recipe} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  recipe_pb.Recipe.deserializeBinary
);


/**
 * @param {!proto.pb.Recipe} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Recipe>}
 *     The XHR Node Readable Stream
 */
proto.pb.LibraryServiceClient.prototype.filterRecipes =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.LibraryService/FilterRecipes',
      request,
      metadata || {},
      methodDescriptor_LibraryService_FilterRecipes);
};


/**
 * @param {!proto.pb.Recipe} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Recipe>}
 *     The XHR Node Readable Stream
 */
proto.pb.LibraryServicePromiseClient.prototype.filterRecipes =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.LibraryService/FilterRecipes',
      request,
      metadata || {},
      methodDescriptor_LibraryService_FilterRecipes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Recipe,
 *   !proto.pb.Recipe>}
 */
const methodDescriptor_LibraryService_WatchRecipes = new grpc.web.MethodDescriptor(
  '/pb.LibraryService/WatchRecipes',
  grpc.web.MethodType.SERVER_STREAMING,
  recipe_pb.Recipe,
  recipe_pb.Recipe,
  /**
   * @param {!proto.pb.Recipe} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  recipe_pb.Recipe.deserializeBinary
);


/**
 * @param {!proto.pb.Recipe} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Recipe>}
 *     The XHR Node Readable Stream
 */
proto.pb.LibraryServiceClient.prototype.watchRecipes =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.LibraryService/WatchRecipes',
      request,
      metadata || {},
      methodDescriptor_LibraryService_WatchRecipes);
};


/**
 * @param {!proto.pb.Recipe} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Recipe>}
 *     The XHR Node Readable Stream
 */
proto.pb.LibraryServicePromiseClient.prototype.watchRecipes =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.LibraryService/WatchRecipes',
      request,
      metadata || {},
      methodDescriptor_LibraryService_WatchRecipes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Recipe,
 *   !proto.pb.Recipe>}
 */
const methodDescriptor_LibraryService_ApproveRecipe = new grpc.web.MethodDescriptor(
  '/pb.LibraryService/ApproveRecipe',
  grpc.web.MethodType.UNARY,
  recipe_pb.Recipe,
  recipe_pb.Recipe,
  /**
   * @param {!proto.pb.Recipe} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  recipe_pb.Recipe.deserializeBinary
);


/**
 * @param {!proto.pb.Recipe} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Recipe)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Recipe>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.LibraryServiceClient.prototype.approveRecipe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.LibraryService/ApproveRecipe',
      request,
      metadata || {},
      methodDescriptor_LibraryService_ApproveRecipe,
      callback);
};


/**
 * @param {!proto.pb.Recipe} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Recipe>}
 *     Promise that resolves to the response
 */
proto.pb.LibraryServicePromiseClient.prototype.approveRecipe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.LibraryService/ApproveRecipe',
      request,
      metadata || {},
      methodDescriptor_LibraryService_ApproveRecipe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Recipe,
 *   !proto.pb.Recipe>}
 */
const methodDescriptor_LibraryService_UpdateRecipe = new grpc.web.MethodDescriptor(
  '/pb.LibraryService/UpdateRecipe',
  grpc.web.MethodType.UNARY,
  recipe_pb.Recipe,
  recipe_pb.Recipe,
  /**
   * @param {!proto.pb.Recipe} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  recipe_pb.Recipe.deserializeBinary
);


/**
 * @param {!proto.pb.Recipe} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Recipe)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Recipe>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.LibraryServiceClient.prototype.updateRecipe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.LibraryService/UpdateRecipe',
      request,
      metadata || {},
      methodDescriptor_LibraryService_UpdateRecipe,
      callback);
};


/**
 * @param {!proto.pb.Recipe} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Recipe>}
 *     Promise that resolves to the response
 */
proto.pb.LibraryServicePromiseClient.prototype.updateRecipe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.LibraryService/UpdateRecipe',
      request,
      metadata || {},
      methodDescriptor_LibraryService_UpdateRecipe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Recipe,
 *   !proto.pb.Recipe>}
 */
const methodDescriptor_LibraryService_DeleteRecipe = new grpc.web.MethodDescriptor(
  '/pb.LibraryService/DeleteRecipe',
  grpc.web.MethodType.UNARY,
  recipe_pb.Recipe,
  recipe_pb.Recipe,
  /**
   * @param {!proto.pb.Recipe} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  recipe_pb.Recipe.deserializeBinary
);


/**
 * @param {!proto.pb.Recipe} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Recipe)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Recipe>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.LibraryServiceClient.prototype.deleteRecipe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.LibraryService/DeleteRecipe',
      request,
      metadata || {},
      methodDescriptor_LibraryService_DeleteRecipe,
      callback);
};


/**
 * @param {!proto.pb.Recipe} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Recipe>}
 *     Promise that resolves to the response
 */
proto.pb.LibraryServicePromiseClient.prototype.deleteRecipe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.LibraryService/DeleteRecipe',
      request,
      metadata || {},
      methodDescriptor_LibraryService_DeleteRecipe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.RecipeAccess,
 *   !proto.pb.RecipeAccess>}
 */
const methodDescriptor_LibraryService_GetRecipeAccess = new grpc.web.MethodDescriptor(
  '/pb.LibraryService/GetRecipeAccess',
  grpc.web.MethodType.UNARY,
  recipe_pb.RecipeAccess,
  recipe_pb.RecipeAccess,
  /**
   * @param {!proto.pb.RecipeAccess} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  recipe_pb.RecipeAccess.deserializeBinary
);


/**
 * @param {!proto.pb.RecipeAccess} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.RecipeAccess)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.RecipeAccess>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.LibraryServiceClient.prototype.getRecipeAccess =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.LibraryService/GetRecipeAccess',
      request,
      metadata || {},
      methodDescriptor_LibraryService_GetRecipeAccess,
      callback);
};


/**
 * @param {!proto.pb.RecipeAccess} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.RecipeAccess>}
 *     Promise that resolves to the response
 */
proto.pb.LibraryServicePromiseClient.prototype.getRecipeAccess =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.LibraryService/GetRecipeAccess',
      request,
      metadata || {},
      methodDescriptor_LibraryService_GetRecipeAccess);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.RecipeAccess,
 *   !proto.pb.RecipeAccess>}
 */
const methodDescriptor_LibraryService_SetRecipeAccess = new grpc.web.MethodDescriptor(
  '/pb.LibraryService/SetRecipeAccess',
  grpc.web.MethodType.UNARY,
  recipe_pb.RecipeAccess,
  recipe_pb.RecipeAccess,
  /**
   * @param {!proto.pb.RecipeAccess} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  recipe_pb.RecipeAccess.deserializeBinary
);


/**
 * @param {!proto.pb.RecipeAccess} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.RecipeAccess)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.RecipeAccess>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.LibraryServiceClient.prototype.setRecipeAccess =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.LibraryService/SetRecipeAccess',
      request,
      metadata || {},
      methodDescriptor_LibraryService_SetRecipeAccess,
      callback);
};


/**
 * @param {!proto.pb.RecipeAccess} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.RecipeAccess>}
 *     Promise that resolves to the response
 */
proto.pb.LibraryServicePromiseClient.prototype.setRecipeAccess =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.LibraryService/SetRecipeAccess',
      request,
      metadata || {},
      methodDescriptor_LibraryService_SetRecipeAccess);
};


module.exports = proto.pb;

