/**
 * @fileoverview gRPC-Web generated client stub for pb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: store_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var agent_pb = require('./agent_pb.js')

var instance_pb = require('./instance_pb.js')

var console_pb = require('./console_pb.js')

var web_pb = require('./web_pb.js')

var publishpacket_pb = require('./publishpacket_pb.js')

var request_pb = require('./request_pb.js')

var watcher_pb = require('./watcher_pb.js')

var alarm_pb = require('./alarm_pb.js')

var config_pb = require('./config_pb.js')

var problem_pb = require('./problem_pb.js')

var agent_state_pb = require('./agent_state_pb.js')
const proto = {};
proto.pb = require('./store_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.StoreServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.StoreServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.PublishPacket,
 *   !proto.pb.PublishPacket>}
 */
const methodDescriptor_StoreService_OnMessage = new grpc.web.MethodDescriptor(
  '/pb.StoreService/OnMessage',
  grpc.web.MethodType.UNARY,
  publishpacket_pb.PublishPacket,
  publishpacket_pb.PublishPacket,
  /**
   * @param {!proto.pb.PublishPacket} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  publishpacket_pb.PublishPacket.deserializeBinary
);


/**
 * @param {!proto.pb.PublishPacket} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.PublishPacket)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.PublishPacket>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.onMessage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.StoreService/OnMessage',
      request,
      metadata || {},
      methodDescriptor_StoreService_OnMessage,
      callback);
};


/**
 * @param {!proto.pb.PublishPacket} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.PublishPacket>}
 *     Promise that resolves to the response
 */
proto.pb.StoreServicePromiseClient.prototype.onMessage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.StoreService/OnMessage',
      request,
      metadata || {},
      methodDescriptor_StoreService_OnMessage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.PublishPacket,
 *   !proto.pb.PublishPacket>}
 */
const methodDescriptor_StoreService_OnConsoleMessage = new grpc.web.MethodDescriptor(
  '/pb.StoreService/OnConsoleMessage',
  grpc.web.MethodType.UNARY,
  publishpacket_pb.PublishPacket,
  publishpacket_pb.PublishPacket,
  /**
   * @param {!proto.pb.PublishPacket} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  publishpacket_pb.PublishPacket.deserializeBinary
);


/**
 * @param {!proto.pb.PublishPacket} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.PublishPacket)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.PublishPacket>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.onConsoleMessage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.StoreService/OnConsoleMessage',
      request,
      metadata || {},
      methodDescriptor_StoreService_OnConsoleMessage,
      callback);
};


/**
 * @param {!proto.pb.PublishPacket} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.PublishPacket>}
 *     Promise that resolves to the response
 */
proto.pb.StoreServicePromiseClient.prototype.onConsoleMessage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.StoreService/OnConsoleMessage',
      request,
      metadata || {},
      methodDescriptor_StoreService_OnConsoleMessage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.ConsoleMessage,
 *   !proto.pb.ConsoleMessage>}
 */
const methodDescriptor_StoreService_OpenConsole = new grpc.web.MethodDescriptor(
  '/pb.StoreService/OpenConsole',
  grpc.web.MethodType.UNARY,
  console_pb.ConsoleMessage,
  console_pb.ConsoleMessage,
  /**
   * @param {!proto.pb.ConsoleMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  console_pb.ConsoleMessage.deserializeBinary
);


/**
 * @param {!proto.pb.ConsoleMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.ConsoleMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.ConsoleMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.openConsole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.StoreService/OpenConsole',
      request,
      metadata || {},
      methodDescriptor_StoreService_OpenConsole,
      callback);
};


/**
 * @param {!proto.pb.ConsoleMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.ConsoleMessage>}
 *     Promise that resolves to the response
 */
proto.pb.StoreServicePromiseClient.prototype.openConsole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.StoreService/OpenConsole',
      request,
      metadata || {},
      methodDescriptor_StoreService_OpenConsole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.ConsoleMessage,
 *   !proto.pb.ConsoleMessage>}
 */
const methodDescriptor_StoreService_CloseConsole = new grpc.web.MethodDescriptor(
  '/pb.StoreService/CloseConsole',
  grpc.web.MethodType.UNARY,
  console_pb.ConsoleMessage,
  console_pb.ConsoleMessage,
  /**
   * @param {!proto.pb.ConsoleMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  console_pb.ConsoleMessage.deserializeBinary
);


/**
 * @param {!proto.pb.ConsoleMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.ConsoleMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.ConsoleMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.closeConsole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.StoreService/CloseConsole',
      request,
      metadata || {},
      methodDescriptor_StoreService_CloseConsole,
      callback);
};


/**
 * @param {!proto.pb.ConsoleMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.ConsoleMessage>}
 *     Promise that resolves to the response
 */
proto.pb.StoreServicePromiseClient.prototype.closeConsole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.StoreService/CloseConsole',
      request,
      metadata || {},
      methodDescriptor_StoreService_CloseConsole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.PublishPacket,
 *   !proto.pb.PublishPacket>}
 */
const methodDescriptor_StoreService_OnWebMessage = new grpc.web.MethodDescriptor(
  '/pb.StoreService/OnWebMessage',
  grpc.web.MethodType.UNARY,
  publishpacket_pb.PublishPacket,
  publishpacket_pb.PublishPacket,
  /**
   * @param {!proto.pb.PublishPacket} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  publishpacket_pb.PublishPacket.deserializeBinary
);


/**
 * @param {!proto.pb.PublishPacket} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.PublishPacket)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.PublishPacket>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.onWebMessage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.StoreService/OnWebMessage',
      request,
      metadata || {},
      methodDescriptor_StoreService_OnWebMessage,
      callback);
};


/**
 * @param {!proto.pb.PublishPacket} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.PublishPacket>}
 *     Promise that resolves to the response
 */
proto.pb.StoreServicePromiseClient.prototype.onWebMessage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.StoreService/OnWebMessage',
      request,
      metadata || {},
      methodDescriptor_StoreService_OnWebMessage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.WebMessage,
 *   !proto.pb.WebMessage>}
 */
const methodDescriptor_StoreService_OpenWeb = new grpc.web.MethodDescriptor(
  '/pb.StoreService/OpenWeb',
  grpc.web.MethodType.UNARY,
  web_pb.WebMessage,
  web_pb.WebMessage,
  /**
   * @param {!proto.pb.WebMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  web_pb.WebMessage.deserializeBinary
);


/**
 * @param {!proto.pb.WebMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.WebMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.WebMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.openWeb =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.StoreService/OpenWeb',
      request,
      metadata || {},
      methodDescriptor_StoreService_OpenWeb,
      callback);
};


/**
 * @param {!proto.pb.WebMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.WebMessage>}
 *     Promise that resolves to the response
 */
proto.pb.StoreServicePromiseClient.prototype.openWeb =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.StoreService/OpenWeb',
      request,
      metadata || {},
      methodDescriptor_StoreService_OpenWeb);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.WebMessage,
 *   !proto.pb.WebMessage>}
 */
const methodDescriptor_StoreService_CloseWeb = new grpc.web.MethodDescriptor(
  '/pb.StoreService/CloseWeb',
  grpc.web.MethodType.UNARY,
  web_pb.WebMessage,
  web_pb.WebMessage,
  /**
   * @param {!proto.pb.WebMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  web_pb.WebMessage.deserializeBinary
);


/**
 * @param {!proto.pb.WebMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.WebMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.WebMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.closeWeb =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.StoreService/CloseWeb',
      request,
      metadata || {},
      methodDescriptor_StoreService_CloseWeb,
      callback);
};


/**
 * @param {!proto.pb.WebMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.WebMessage>}
 *     Promise that resolves to the response
 */
proto.pb.StoreServicePromiseClient.prototype.closeWeb =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.StoreService/CloseWeb',
      request,
      metadata || {},
      methodDescriptor_StoreService_CloseWeb);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Agent,
 *   !proto.pb.Agent>}
 */
const methodDescriptor_StoreService_GetAgent = new grpc.web.MethodDescriptor(
  '/pb.StoreService/GetAgent',
  grpc.web.MethodType.UNARY,
  agent_pb.Agent,
  agent_pb.Agent,
  /**
   * @param {!proto.pb.Agent} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  agent_pb.Agent.deserializeBinary
);


/**
 * @param {!proto.pb.Agent} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Agent)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Agent>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.getAgent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.StoreService/GetAgent',
      request,
      metadata || {},
      methodDescriptor_StoreService_GetAgent,
      callback);
};


/**
 * @param {!proto.pb.Agent} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Agent>}
 *     Promise that resolves to the response
 */
proto.pb.StoreServicePromiseClient.prototype.getAgent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.StoreService/GetAgent',
      request,
      metadata || {},
      methodDescriptor_StoreService_GetAgent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Agent,
 *   !proto.pb.Agent>}
 */
const methodDescriptor_StoreService_DeleteAgent = new grpc.web.MethodDescriptor(
  '/pb.StoreService/DeleteAgent',
  grpc.web.MethodType.UNARY,
  agent_pb.Agent,
  agent_pb.Agent,
  /**
   * @param {!proto.pb.Agent} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  agent_pb.Agent.deserializeBinary
);


/**
 * @param {!proto.pb.Agent} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Agent)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Agent>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.deleteAgent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.StoreService/DeleteAgent',
      request,
      metadata || {},
      methodDescriptor_StoreService_DeleteAgent,
      callback);
};


/**
 * @param {!proto.pb.Agent} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Agent>}
 *     Promise that resolves to the response
 */
proto.pb.StoreServicePromiseClient.prototype.deleteAgent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.StoreService/DeleteAgent',
      request,
      metadata || {},
      methodDescriptor_StoreService_DeleteAgent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Agent,
 *   !proto.pb.Agent>}
 */
const methodDescriptor_StoreService_FilterAgents = new grpc.web.MethodDescriptor(
  '/pb.StoreService/FilterAgents',
  grpc.web.MethodType.SERVER_STREAMING,
  agent_pb.Agent,
  agent_pb.Agent,
  /**
   * @param {!proto.pb.Agent} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  agent_pb.Agent.deserializeBinary
);


/**
 * @param {!proto.pb.Agent} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Agent>}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.filterAgents =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.StoreService/FilterAgents',
      request,
      metadata || {},
      methodDescriptor_StoreService_FilterAgents);
};


/**
 * @param {!proto.pb.Agent} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Agent>}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServicePromiseClient.prototype.filterAgents =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.StoreService/FilterAgents',
      request,
      metadata || {},
      methodDescriptor_StoreService_FilterAgents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Agent,
 *   !proto.pb.Agent>}
 */
const methodDescriptor_StoreService_WatchAgents = new grpc.web.MethodDescriptor(
  '/pb.StoreService/WatchAgents',
  grpc.web.MethodType.SERVER_STREAMING,
  agent_pb.Agent,
  agent_pb.Agent,
  /**
   * @param {!proto.pb.Agent} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  agent_pb.Agent.deserializeBinary
);


/**
 * @param {!proto.pb.Agent} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Agent>}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.watchAgents =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.StoreService/WatchAgents',
      request,
      metadata || {},
      methodDescriptor_StoreService_WatchAgents);
};


/**
 * @param {!proto.pb.Agent} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Agent>}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServicePromiseClient.prototype.watchAgents =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.StoreService/WatchAgents',
      request,
      metadata || {},
      methodDescriptor_StoreService_WatchAgents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Request,
 *   !proto.pb.Agent>}
 */
const methodDescriptor_StoreService_GetApplicableAgents = new grpc.web.MethodDescriptor(
  '/pb.StoreService/GetApplicableAgents',
  grpc.web.MethodType.SERVER_STREAMING,
  request_pb.Request,
  agent_pb.Agent,
  /**
   * @param {!proto.pb.Request} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  agent_pb.Agent.deserializeBinary
);


/**
 * @param {!proto.pb.Request} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Agent>}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.getApplicableAgents =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.StoreService/GetApplicableAgents',
      request,
      metadata || {},
      methodDescriptor_StoreService_GetApplicableAgents);
};


/**
 * @param {!proto.pb.Request} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Agent>}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServicePromiseClient.prototype.getApplicableAgents =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.StoreService/GetApplicableAgents',
      request,
      metadata || {},
      methodDescriptor_StoreService_GetApplicableAgents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Instance,
 *   !proto.pb.Instance>}
 */
const methodDescriptor_StoreService_GetInstance = new grpc.web.MethodDescriptor(
  '/pb.StoreService/GetInstance',
  grpc.web.MethodType.UNARY,
  instance_pb.Instance,
  instance_pb.Instance,
  /**
   * @param {!proto.pb.Instance} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  instance_pb.Instance.deserializeBinary
);


/**
 * @param {!proto.pb.Instance} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Instance)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Instance>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.getInstance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.StoreService/GetInstance',
      request,
      metadata || {},
      methodDescriptor_StoreService_GetInstance,
      callback);
};


/**
 * @param {!proto.pb.Instance} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Instance>}
 *     Promise that resolves to the response
 */
proto.pb.StoreServicePromiseClient.prototype.getInstance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.StoreService/GetInstance',
      request,
      metadata || {},
      methodDescriptor_StoreService_GetInstance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.InstanceRange,
 *   !proto.pb.Instance>}
 */
const methodDescriptor_StoreService_FilterInstances = new grpc.web.MethodDescriptor(
  '/pb.StoreService/FilterInstances',
  grpc.web.MethodType.SERVER_STREAMING,
  instance_pb.InstanceRange,
  instance_pb.Instance,
  /**
   * @param {!proto.pb.InstanceRange} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  instance_pb.Instance.deserializeBinary
);


/**
 * @param {!proto.pb.InstanceRange} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Instance>}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.filterInstances =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.StoreService/FilterInstances',
      request,
      metadata || {},
      methodDescriptor_StoreService_FilterInstances);
};


/**
 * @param {!proto.pb.InstanceRange} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Instance>}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServicePromiseClient.prototype.filterInstances =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.StoreService/FilterInstances',
      request,
      metadata || {},
      methodDescriptor_StoreService_FilterInstances);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Instance,
 *   !proto.pb.Instance>}
 */
const methodDescriptor_StoreService_FilterMemInstances = new grpc.web.MethodDescriptor(
  '/pb.StoreService/FilterMemInstances',
  grpc.web.MethodType.SERVER_STREAMING,
  instance_pb.Instance,
  instance_pb.Instance,
  /**
   * @param {!proto.pb.Instance} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  instance_pb.Instance.deserializeBinary
);


/**
 * @param {!proto.pb.Instance} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Instance>}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.filterMemInstances =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.StoreService/FilterMemInstances',
      request,
      metadata || {},
      methodDescriptor_StoreService_FilterMemInstances);
};


/**
 * @param {!proto.pb.Instance} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Instance>}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServicePromiseClient.prototype.filterMemInstances =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.StoreService/FilterMemInstances',
      request,
      metadata || {},
      methodDescriptor_StoreService_FilterMemInstances);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Instance,
 *   !proto.pb.Instance>}
 */
const methodDescriptor_StoreService_DeregisterInstance = new grpc.web.MethodDescriptor(
  '/pb.StoreService/DeregisterInstance',
  grpc.web.MethodType.UNARY,
  instance_pb.Instance,
  instance_pb.Instance,
  /**
   * @param {!proto.pb.Instance} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  instance_pb.Instance.deserializeBinary
);


/**
 * @param {!proto.pb.Instance} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Instance)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Instance>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.deregisterInstance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.StoreService/DeregisterInstance',
      request,
      metadata || {},
      methodDescriptor_StoreService_DeregisterInstance,
      callback);
};


/**
 * @param {!proto.pb.Instance} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Instance>}
 *     Promise that resolves to the response
 */
proto.pb.StoreServicePromiseClient.prototype.deregisterInstance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.StoreService/DeregisterInstance',
      request,
      metadata || {},
      methodDescriptor_StoreService_DeregisterInstance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Config,
 *   !proto.pb.Config>}
 */
const methodDescriptor_StoreService_ApplyWatchers = new grpc.web.MethodDescriptor(
  '/pb.StoreService/ApplyWatchers',
  grpc.web.MethodType.UNARY,
  config_pb.Config,
  config_pb.Config,
  /**
   * @param {!proto.pb.Config} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  config_pb.Config.deserializeBinary
);


/**
 * @param {!proto.pb.Config} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Config)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Config>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.applyWatchers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.StoreService/ApplyWatchers',
      request,
      metadata || {},
      methodDescriptor_StoreService_ApplyWatchers,
      callback);
};


/**
 * @param {!proto.pb.Config} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Config>}
 *     Promise that resolves to the response
 */
proto.pb.StoreServicePromiseClient.prototype.applyWatchers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.StoreService/ApplyWatchers',
      request,
      metadata || {},
      methodDescriptor_StoreService_ApplyWatchers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Watcher,
 *   !proto.pb.Watcher>}
 */
const methodDescriptor_StoreService_SetWatcher = new grpc.web.MethodDescriptor(
  '/pb.StoreService/SetWatcher',
  grpc.web.MethodType.UNARY,
  watcher_pb.Watcher,
  watcher_pb.Watcher,
  /**
   * @param {!proto.pb.Watcher} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  watcher_pb.Watcher.deserializeBinary
);


/**
 * @param {!proto.pb.Watcher} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Watcher)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Watcher>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.setWatcher =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.StoreService/SetWatcher',
      request,
      metadata || {},
      methodDescriptor_StoreService_SetWatcher,
      callback);
};


/**
 * @param {!proto.pb.Watcher} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Watcher>}
 *     Promise that resolves to the response
 */
proto.pb.StoreServicePromiseClient.prototype.setWatcher =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.StoreService/SetWatcher',
      request,
      metadata || {},
      methodDescriptor_StoreService_SetWatcher);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Watcher,
 *   !proto.pb.Watcher>}
 */
const methodDescriptor_StoreService_DeleteWatcher = new grpc.web.MethodDescriptor(
  '/pb.StoreService/DeleteWatcher',
  grpc.web.MethodType.UNARY,
  watcher_pb.Watcher,
  watcher_pb.Watcher,
  /**
   * @param {!proto.pb.Watcher} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  watcher_pb.Watcher.deserializeBinary
);


/**
 * @param {!proto.pb.Watcher} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Watcher)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Watcher>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.deleteWatcher =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.StoreService/DeleteWatcher',
      request,
      metadata || {},
      methodDescriptor_StoreService_DeleteWatcher,
      callback);
};


/**
 * @param {!proto.pb.Watcher} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Watcher>}
 *     Promise that resolves to the response
 */
proto.pb.StoreServicePromiseClient.prototype.deleteWatcher =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.StoreService/DeleteWatcher',
      request,
      metadata || {},
      methodDescriptor_StoreService_DeleteWatcher);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Watcher,
 *   !proto.pb.WatcherMap>}
 */
const methodDescriptor_StoreService_FilterWatchers = new grpc.web.MethodDescriptor(
  '/pb.StoreService/FilterWatchers',
  grpc.web.MethodType.SERVER_STREAMING,
  watcher_pb.Watcher,
  watcher_pb.WatcherMap,
  /**
   * @param {!proto.pb.Watcher} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  watcher_pb.WatcherMap.deserializeBinary
);


/**
 * @param {!proto.pb.Watcher} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.WatcherMap>}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.filterWatchers =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.StoreService/FilterWatchers',
      request,
      metadata || {},
      methodDescriptor_StoreService_FilterWatchers);
};


/**
 * @param {!proto.pb.Watcher} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.WatcherMap>}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServicePromiseClient.prototype.filterWatchers =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.StoreService/FilterWatchers',
      request,
      metadata || {},
      methodDescriptor_StoreService_FilterWatchers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Watcher,
 *   !proto.pb.WatcherMap>}
 */
const methodDescriptor_StoreService_WatchWatchers = new grpc.web.MethodDescriptor(
  '/pb.StoreService/WatchWatchers',
  grpc.web.MethodType.SERVER_STREAMING,
  watcher_pb.Watcher,
  watcher_pb.WatcherMap,
  /**
   * @param {!proto.pb.Watcher} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  watcher_pb.WatcherMap.deserializeBinary
);


/**
 * @param {!proto.pb.Watcher} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.WatcherMap>}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.watchWatchers =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.StoreService/WatchWatchers',
      request,
      metadata || {},
      methodDescriptor_StoreService_WatchWatchers);
};


/**
 * @param {!proto.pb.Watcher} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.WatcherMap>}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServicePromiseClient.prototype.watchWatchers =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.StoreService/WatchWatchers',
      request,
      metadata || {},
      methodDescriptor_StoreService_WatchWatchers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.AlarmRange,
 *   !proto.pb.Alarm>}
 */
const methodDescriptor_StoreService_FilterAlarms = new grpc.web.MethodDescriptor(
  '/pb.StoreService/FilterAlarms',
  grpc.web.MethodType.SERVER_STREAMING,
  alarm_pb.AlarmRange,
  alarm_pb.Alarm,
  /**
   * @param {!proto.pb.AlarmRange} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  alarm_pb.Alarm.deserializeBinary
);


/**
 * @param {!proto.pb.AlarmRange} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Alarm>}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.filterAlarms =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.StoreService/FilterAlarms',
      request,
      metadata || {},
      methodDescriptor_StoreService_FilterAlarms);
};


/**
 * @param {!proto.pb.AlarmRange} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Alarm>}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServicePromiseClient.prototype.filterAlarms =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.StoreService/FilterAlarms',
      request,
      metadata || {},
      methodDescriptor_StoreService_FilterAlarms);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Alarm,
 *   !proto.pb.Alarm>}
 */
const methodDescriptor_StoreService_GetAlarm = new grpc.web.MethodDescriptor(
  '/pb.StoreService/GetAlarm',
  grpc.web.MethodType.UNARY,
  alarm_pb.Alarm,
  alarm_pb.Alarm,
  /**
   * @param {!proto.pb.Alarm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  alarm_pb.Alarm.deserializeBinary
);


/**
 * @param {!proto.pb.Alarm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Alarm)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Alarm>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.getAlarm =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.StoreService/GetAlarm',
      request,
      metadata || {},
      methodDescriptor_StoreService_GetAlarm,
      callback);
};


/**
 * @param {!proto.pb.Alarm} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Alarm>}
 *     Promise that resolves to the response
 */
proto.pb.StoreServicePromiseClient.prototype.getAlarm =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.StoreService/GetAlarm',
      request,
      metadata || {},
      methodDescriptor_StoreService_GetAlarm);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Alarm,
 *   !proto.pb.Alarm>}
 */
const methodDescriptor_StoreService_WatchAlarms = new grpc.web.MethodDescriptor(
  '/pb.StoreService/WatchAlarms',
  grpc.web.MethodType.SERVER_STREAMING,
  alarm_pb.Alarm,
  alarm_pb.Alarm,
  /**
   * @param {!proto.pb.Alarm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  alarm_pb.Alarm.deserializeBinary
);


/**
 * @param {!proto.pb.Alarm} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Alarm>}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.watchAlarms =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.StoreService/WatchAlarms',
      request,
      metadata || {},
      methodDescriptor_StoreService_WatchAlarms);
};


/**
 * @param {!proto.pb.Alarm} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Alarm>}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServicePromiseClient.prototype.watchAlarms =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.StoreService/WatchAlarms',
      request,
      metadata || {},
      methodDescriptor_StoreService_WatchAlarms);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Config,
 *   !proto.pb.Config>}
 */
const methodDescriptor_StoreService_GetConfig = new grpc.web.MethodDescriptor(
  '/pb.StoreService/GetConfig',
  grpc.web.MethodType.UNARY,
  config_pb.Config,
  config_pb.Config,
  /**
   * @param {!proto.pb.Config} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  config_pb.Config.deserializeBinary
);


/**
 * @param {!proto.pb.Config} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Config)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Config>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.getConfig =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.StoreService/GetConfig',
      request,
      metadata || {},
      methodDescriptor_StoreService_GetConfig,
      callback);
};


/**
 * @param {!proto.pb.Config} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Config>}
 *     Promise that resolves to the response
 */
proto.pb.StoreServicePromiseClient.prototype.getConfig =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.StoreService/GetConfig',
      request,
      metadata || {},
      methodDescriptor_StoreService_GetConfig);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Config,
 *   !proto.pb.Config>}
 */
const methodDescriptor_StoreService_WatchConfig = new grpc.web.MethodDescriptor(
  '/pb.StoreService/WatchConfig',
  grpc.web.MethodType.SERVER_STREAMING,
  config_pb.Config,
  config_pb.Config,
  /**
   * @param {!proto.pb.Config} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  config_pb.Config.deserializeBinary
);


/**
 * @param {!proto.pb.Config} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Config>}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.watchConfig =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.StoreService/WatchConfig',
      request,
      metadata || {},
      methodDescriptor_StoreService_WatchConfig);
};


/**
 * @param {!proto.pb.Config} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Config>}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServicePromiseClient.prototype.watchConfig =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.StoreService/WatchConfig',
      request,
      metadata || {},
      methodDescriptor_StoreService_WatchConfig);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.UserConfig,
 *   !proto.pb.UserConfig>}
 */
const methodDescriptor_StoreService_GetUserConfig = new grpc.web.MethodDescriptor(
  '/pb.StoreService/GetUserConfig',
  grpc.web.MethodType.UNARY,
  config_pb.UserConfig,
  config_pb.UserConfig,
  /**
   * @param {!proto.pb.UserConfig} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  config_pb.UserConfig.deserializeBinary
);


/**
 * @param {!proto.pb.UserConfig} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.UserConfig)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.UserConfig>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.getUserConfig =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.StoreService/GetUserConfig',
      request,
      metadata || {},
      methodDescriptor_StoreService_GetUserConfig,
      callback);
};


/**
 * @param {!proto.pb.UserConfig} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.UserConfig>}
 *     Promise that resolves to the response
 */
proto.pb.StoreServicePromiseClient.prototype.getUserConfig =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.StoreService/GetUserConfig',
      request,
      metadata || {},
      methodDescriptor_StoreService_GetUserConfig);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.UserConfig,
 *   !proto.pb.UserConfig>}
 */
const methodDescriptor_StoreService_SetUserConfig = new grpc.web.MethodDescriptor(
  '/pb.StoreService/SetUserConfig',
  grpc.web.MethodType.UNARY,
  config_pb.UserConfig,
  config_pb.UserConfig,
  /**
   * @param {!proto.pb.UserConfig} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  config_pb.UserConfig.deserializeBinary
);


/**
 * @param {!proto.pb.UserConfig} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.UserConfig)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.UserConfig>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.setUserConfig =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.StoreService/SetUserConfig',
      request,
      metadata || {},
      methodDescriptor_StoreService_SetUserConfig,
      callback);
};


/**
 * @param {!proto.pb.UserConfig} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.UserConfig>}
 *     Promise that resolves to the response
 */
proto.pb.StoreServicePromiseClient.prototype.setUserConfig =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.StoreService/SetUserConfig',
      request,
      metadata || {},
      methodDescriptor_StoreService_SetUserConfig);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Notification,
 *   !proto.pb.Notification>}
 */
const methodDescriptor_StoreService_SetNotification = new grpc.web.MethodDescriptor(
  '/pb.StoreService/SetNotification',
  grpc.web.MethodType.UNARY,
  config_pb.Notification,
  config_pb.Notification,
  /**
   * @param {!proto.pb.Notification} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  config_pb.Notification.deserializeBinary
);


/**
 * @param {!proto.pb.Notification} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Notification)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Notification>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.setNotification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.StoreService/SetNotification',
      request,
      metadata || {},
      methodDescriptor_StoreService_SetNotification,
      callback);
};


/**
 * @param {!proto.pb.Notification} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Notification>}
 *     Promise that resolves to the response
 */
proto.pb.StoreServicePromiseClient.prototype.setNotification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.StoreService/SetNotification',
      request,
      metadata || {},
      methodDescriptor_StoreService_SetNotification);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.ProblemRange,
 *   !proto.pb.Problem>}
 */
const methodDescriptor_StoreService_FilterProblems = new grpc.web.MethodDescriptor(
  '/pb.StoreService/FilterProblems',
  grpc.web.MethodType.SERVER_STREAMING,
  problem_pb.ProblemRange,
  problem_pb.Problem,
  /**
   * @param {!proto.pb.ProblemRange} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  problem_pb.Problem.deserializeBinary
);


/**
 * @param {!proto.pb.ProblemRange} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Problem>}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.filterProblems =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.StoreService/FilterProblems',
      request,
      metadata || {},
      methodDescriptor_StoreService_FilterProblems);
};


/**
 * @param {!proto.pb.ProblemRange} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Problem>}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServicePromiseClient.prototype.filterProblems =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.StoreService/FilterProblems',
      request,
      metadata || {},
      methodDescriptor_StoreService_FilterProblems);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.AgentState,
 *   !proto.pb.AgentState>}
 */
const methodDescriptor_StoreService_GetAgentState = new grpc.web.MethodDescriptor(
  '/pb.StoreService/GetAgentState',
  grpc.web.MethodType.UNARY,
  agent_state_pb.AgentState,
  agent_state_pb.AgentState,
  /**
   * @param {!proto.pb.AgentState} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  agent_state_pb.AgentState.deserializeBinary
);


/**
 * @param {!proto.pb.AgentState} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.AgentState)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.AgentState>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.StoreServiceClient.prototype.getAgentState =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.StoreService/GetAgentState',
      request,
      metadata || {},
      methodDescriptor_StoreService_GetAgentState,
      callback);
};


/**
 * @param {!proto.pb.AgentState} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.AgentState>}
 *     Promise that resolves to the response
 */
proto.pb.StoreServicePromiseClient.prototype.getAgentState =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.StoreService/GetAgentState',
      request,
      metadata || {},
      methodDescriptor_StoreService_GetAgentState);
};


module.exports = proto.pb;

