/**
 * @fileoverview gRPC-Web generated client stub for pb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: svn_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.pb = require('./svn_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.SvnServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.SvnServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Plant,
 *   !proto.pb.Plant>}
 */
const methodDescriptor_SvnService_FilterPlants = new grpc.web.MethodDescriptor(
  '/pb.SvnService/FilterPlants',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.pb.Plant,
  proto.pb.Plant,
  /**
   * @param {!proto.pb.Plant} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.Plant.deserializeBinary
);


/**
 * @param {!proto.pb.Plant} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Plant>}
 *     The XHR Node Readable Stream
 */
proto.pb.SvnServiceClient.prototype.filterPlants =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.SvnService/FilterPlants',
      request,
      metadata || {},
      methodDescriptor_SvnService_FilterPlants);
};


/**
 * @param {!proto.pb.Plant} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Plant>}
 *     The XHR Node Readable Stream
 */
proto.pb.SvnServicePromiseClient.prototype.filterPlants =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.SvnService/FilterPlants',
      request,
      metadata || {},
      methodDescriptor_SvnService_FilterPlants);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Plant,
 *   !proto.pb.Plant>}
 */
const methodDescriptor_SvnService_RefreshPlants = new grpc.web.MethodDescriptor(
  '/pb.SvnService/RefreshPlants',
  grpc.web.MethodType.UNARY,
  proto.pb.Plant,
  proto.pb.Plant,
  /**
   * @param {!proto.pb.Plant} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.Plant.deserializeBinary
);


/**
 * @param {!proto.pb.Plant} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Plant)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Plant>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.SvnServiceClient.prototype.refreshPlants =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.SvnService/RefreshPlants',
      request,
      metadata || {},
      methodDescriptor_SvnService_RefreshPlants,
      callback);
};


/**
 * @param {!proto.pb.Plant} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Plant>}
 *     Promise that resolves to the response
 */
proto.pb.SvnServicePromiseClient.prototype.refreshPlants =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.SvnService/RefreshPlants',
      request,
      metadata || {},
      methodDescriptor_SvnService_RefreshPlants);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.SvnFileReq,
 *   !proto.pb.SvnFileRes>}
 */
const methodDescriptor_SvnService_GetFile = new grpc.web.MethodDescriptor(
  '/pb.SvnService/GetFile',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.pb.SvnFileReq,
  proto.pb.SvnFileRes,
  /**
   * @param {!proto.pb.SvnFileReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.SvnFileRes.deserializeBinary
);


/**
 * @param {!proto.pb.SvnFileReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.SvnFileRes>}
 *     The XHR Node Readable Stream
 */
proto.pb.SvnServiceClient.prototype.getFile =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.SvnService/GetFile',
      request,
      metadata || {},
      methodDescriptor_SvnService_GetFile);
};


/**
 * @param {!proto.pb.SvnFileReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.SvnFileRes>}
 *     The XHR Node Readable Stream
 */
proto.pb.SvnServicePromiseClient.prototype.getFile =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.SvnService/GetFile',
      request,
      metadata || {},
      methodDescriptor_SvnService_GetFile);
};


module.exports = proto.pb;

