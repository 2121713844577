// source: task_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var request_pb = require('./request_pb.js');
goog.object.extend(proto, request_pb);
var task_pb = require('./task_pb.js');
goog.object.extend(proto, task_pb);
var job_pb = require('./job_pb.js');
goog.object.extend(proto, job_pb);
var file_transfer_pb = require('./file_transfer_pb.js');
goog.object.extend(proto, file_transfer_pb);
